export enum INastixCurrency {
    RAND = 'zar',  // South Africa
    BOTSWANA_PULA = 'bwp',  // Botswana
    MALAYSIAN_RINGGIT = 'myr',  // Malaysia
    GHANAIAN_CEDI = 'ghs',  // Ghana
    LESOTHO_LOTI = 'lsl',  // Lesotho
    KENYAN_SHILLING = 'kes',  // Kenya
    ZAMBIAN_KWACHA = 'zmw',  // Zambia
    NAMIBIAN_DOLLAR = 'nad',  // Namibia
    MAURITIAN_RUPEE = 'mur',  // Mauritius
    EURO = 'eur',  // Cyprus
    BRITISH_POUND = 'gbp',  // Northern Ireland, United Kingdom
    US_DOLLAR = 'usd',  // United States
    CONGOLESE_FRANC = 'cdf',  // Democratic Republic of the Congo
    ROMANIAN_LEU = 'ron',  // Democratic Republic of the Congo
}

export enum LanguageConf {
    ENGLISH = 'English',
    AFRIKAANS = 'Afrikaans'
}

enum Language {
    ENGLISH = 'english',
    AFRIKAANS = 'afrikaans'
}

export enum Participation {
    ATTENDED = 'attended',
    NOT_PARTICIPATING = 'not participating',
    ABSENT = 'absent'
}
  
type LanguageOption = {
    value: Language
    label: string
}
  
const languageOptions: LanguageOption[] = [
    { value: Language.ENGLISH, label: 'English' },
    { value: Language.AFRIKAANS, label: 'Afrikaans' },
]

export enum UserType {
    DEFAULT = '',
    MASTER_ADMINISTRATOR = 'master_administrator',
    MASTER_FRANCHISE_OPERATOR = 'master_franchise_operator',
    FRANCHISEE_OPERATOR = 'franchisee_operator',
    FRANCHISEE_ADMIN = 'franchisee_admin',
    SCHOOL_OPERATOR = 'school_operator',
    INSTRUCTOR = 'instructor',
    ASSISTANT = 'assistant',
}

export enum InastixSystemUserType {
    INASTIX_DEV = 'inastix_dev',
    FRANCHISE_COSTING_ADMIN = 'franchise_costing_admin',
    MEMBER_HANDOUTS_ADMIN = 'member_handouts_admin'
}

export enum CompanyType {
    DEFAULT = '',
    SCHOOL = 'school',
    FRANCHISOR = 'franchisor',
    FRANCHISEE = 'franchisee',
    MASTER_FRANCHISE = 'master_franchise'
}

export enum CompanyTypeExcludeDefault {
    SCHOOL = 'school',
    FRANCHISOR = 'franchisor',
    FRANCHISEE = 'franchisee',
    MASTER_FRANCHISE = 'master_franchise'
}

export enum ClassStatus {
    COMPLETED = 'completed',
    ONGOING = 'ongoing',
    DUE = 'due'
}

export enum Bank {
    FIRST_NATIONAL_BANK = 'first_national_bank',
    STANDARD_BANK = 'standard_bank',
    ABSA = 'absa',
    NEDBANK = 'nedbank',
    CAPITEC = "capitec",
    INVESTEC = "investec",
    AFRICAN_BANK = "african_bank",
    BIDVEST_BANK_GROW = "bidvest_bank_grow",
    TYMEBANK = 'tymebank',
    BCR = 'bcr',
    BANK_OF_CYPRUS = 'bank_of_cyprus'
}

export enum SchoolType {
    BABYNASTIX = 'babynastix',
    MONKEYNASTIX = 'monkeynastix',
    SUPERNASTIX = 'supernastix'
}

export enum SchoolTypeEmail {
    BABYNASTIX = '@babynastix.com',
    MONKEYNASTIX = '@monkeynastix.com',
    SUPERNASTIX = '@supernastix.net'
}

export enum UserSetupStep {
    COMPLETED = -1,
    DETAILS_SETUP = 0,
    CHOOSE_TYPE_OF_USER = 1,
    CHOOSE_COMPANY = 2,
    GET_AUTHORIZED = 3
}

export enum FranchsiseCostingOpertions {
	ADD = 'ADD',
    SUB = 'SUB',
    MUL = 'MUL',
    DIV = 'DIV'
}

export enum CompanySetupStep {
    COMPLETED = -1,
    DETAILS_SETUP = 0,
    CHOOSE_COMPANY = 1,
    FINANCE_AND_TERMS = 2,
    XERO_MANAGEMENT = 3,
    GET_AUTHORIZED = 4
}


export default languageOptions