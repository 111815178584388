import first_national_bank from '@images/banks/icons/fnb.png'
import standard_bank from '@images/banks/icons/stb.png'
import absa from '@images/banks/icons/absa.png'
import nedbank from '@images/banks/icons/nedbank.png'
import capitec from '@images/banks/icons/capitec.png'
import investec from '@images/banks/icons/investec.png'
import african_bank from '@images/banks/icons/african_bank.png'
import bidvest_bank from '@images/banks/icons/bidvest_bank.png'
import tymebank from '@images/banks/icons/tymebank.png'
import bcr from '@images/banks/icons/bcr.png'
import boc from '@images/banks/icons/boc.png'

import { Bank, INastixCurrency, UserType } from '@utils/enums/enums'


export const INastixCurrencyMap = {
    [INastixCurrency.RAND]: 'R', // South Africa
    [INastixCurrency.BOTSWANA_PULA]: 'P', // P for Botswana
    [INastixCurrency.MALAYSIAN_RINGGIT]: 'RM', // RM for Malaysia
    [INastixCurrency.GHANAIAN_CEDI]: 'GH₵', // GH₵ for Ghana
    [INastixCurrency.LESOTHO_LOTI]: 'L', // L for Lesotho
    [INastixCurrency.KENYAN_SHILLING]: 'KSh', // KSh for Kenya
    [INastixCurrency.ZAMBIAN_KWACHA]: 'ZK', // ZK for Zambia
    [INastixCurrency.NAMIBIAN_DOLLAR]: 'N$', // N$ for Namibia
    [INastixCurrency.MAURITIAN_RUPEE]: '₨', // ₨ for Mauritius
    [INastixCurrency.EURO]: '€', // € for Cyprus, France, Malta
    [INastixCurrency.BRITISH_POUND]: '£', // £ for Northern Ireland, United Kingdom
    [INastixCurrency.US_DOLLAR]: '\u0024', // $ for United States
    [INastixCurrency.CONGOLESE_FRANC]: 'FC', // FC for Democratic Republic of the Congo
    [INastixCurrency.ROMANIAN_LEU]: 'RON', // FC for Democratic Republic of the Congo
}


export const BankMap: Record<Bank, string> = {
    [Bank.FIRST_NATIONAL_BANK]: "first national bank",
    [Bank.STANDARD_BANK]: "standard bank",
    [Bank.ABSA]: "absa",
    [Bank.NEDBANK]: "nedbank",
    [Bank.CAPITEC]: "capitec",
    [Bank.INVESTEC]: "investec",
    [Bank.AFRICAN_BANK]: "african bank",
    [Bank.BIDVEST_BANK_GROW]: "bidvest bank grow",
    [Bank.TYMEBANK]: 'tymebank',
    [Bank.BCR]: 'bcr',
    [Bank.BANK_OF_CYPRUS]: 'bank of cyprus'
}

export const BankIconImageMap = {
    'first_national_bank': first_national_bank,
    'standard_bank': standard_bank,
    'absa': absa,
    'nedbank': nedbank,
    'capitec': capitec,
    'investec': investec,
    'african_bank': african_bank,
    'bidvest_bank_grow': bidvest_bank,
    'tymebank': tymebank,
    'bcr': bcr,
    'bank_of_cyprus': boc
}


export const UserMap: Record<UserType, string> = {
    'master_administrator':'master administrator',
    'master_franchise_operator': 'master franchise operator',
    'franchisee_operator': 'franchisee operator',
    'franchisee_admin': 'franchisee admin',
    'school_operator':'school operator',
    'instructor': 'instructor',
    'assistant': 'assistant',
    '': '',
}

export const UserTypeDescriptions: Record<string, string> = {
    'master_franchise_operator': "The manager/owner of a Master Franchise.",
    'franchisee_operator': "The manager/owner of a Franchise, with the authority to manage all Franchise operations.",
    'franchisee_admin': "A user without managerial or ownership rights of a Franchise, but with authority to manage certain business processes.",
    'school_operator': "The manager/owner of a School, with the authority to manage all School operations.",
    'instructor': "Works directly with children in the Monkeynastix class environment.",
    'assistant': "Assists the Instructor with fewer roles and responsibilities.",
}


export const CompanyMap = {
    'school': 'school',
    'franchisor': 'franchisor',
    'franchisee': 'franchisee',
    'master_franchise': 'master franchise'
}


export const CompanyAuthorisedMap: {
    '1': string
    '2': string
    '3': string
} = {
    '1': 'AUTHORISED',
    '2': 'PENDING',
    '3': 'UNAUTHORISED',
}


export const UserAuthorisedMap: {
    '1': string
    '2': string
    '3': string
} = {
    '1': 'AUTHORISED',
    '2': 'PENDING',
    '3': 'UNAUTHORISED',
}


export const SchoolMap: { [key: string]: string }  = {
    'babynastix': 'Babynastix',
    'monkeynastix': 'Monkeynastix',
    'supernastix': 'Supernastix'
}


export const ChildAuthorisationMap = {
    '1': 'authorised', 
    '2': 'pending',
    '3': 'suspended',
    '4': 'pending payment',
    '5': 'on hold',
    '6': 'left'
}