import {useContext, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import {Autocomplete, TextField, Typography} from '@mui/material'

import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { DialogProps } from '@utils/dialogProps/DialogProps'
import BulkChildActions from '@actions/ChildActions/bulkChildActions'
import CloudSyncIcon from '@mui/icons-material/CloudSync'
import { Franchisee } from '@utils/interfaces/interfaces'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import CompanyActions from '@actions/CompanyActions/companyActions'
import { MessageContext } from '@contexts/MessageContext'


const TransferChildrenToOtherFranchiseDialog = (props: DialogProps) => {
	// disable the warning for not using state_manager in this dialog
	// eslint-disable-next-line  
	const {ids, state_manager, onClose, open} = props

	const { t } = useTranslation('transfer_children_to_other_franchise')

    const {setMessage} = useContext(MessageContext)
    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)
    const [franchisees, setFranchisees] = useState<Franchisee[]>([])
    const [franchisee, setFranchisee] = useState<Franchisee | null>()
    const [disable_button, setDisableButton] = useState(false)

    useEffect(() => {
        const company_actions = new CompanyActions()
        company_actions.getAllCompanies()
        .then((data: Franchisee[]) => {
            console.log(data)
            setFranchisees(data)
        })
    }, [change_company_rerender, ids])

	const handleBulkTransfer = async (): Promise<any> => {
        setDisableButton(true)
		const bulk_child_actions = new BulkChildActions()
        if (franchisee) {
            bulk_child_actions.transferChildrenToOtherFranchise({children_ids: ids, new_franchise_id: franchisee.company_uuid})
            .then(data => {
                setMessage(props => ({...props, success: t("children_transferred")}))
            })
            .catch(error => {
                setMessage(props => ({...props, error: error.message}))
                setDisableButton(false)
            })
            .finally(async () => {
                return state_manager.setGetAction(undefined, undefined, {has_school: true, archive: false, is_old: false})
                .then(() => {
                    setDisableButton(false)
                    handleClose()
                })
            })
        } else {
            setMessage(props => ({...props, error: t("please_select_a_franchise_to_transfer_to")}))
        }
            
	}

    const handleSelect = (event: any, value: Franchisee | null) => {
            setFranchisee(value)
    }

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> {t("transfer_children_to_franchise")} </INastixDialogTitle>
			<DialogDivider/>
            <Autocomplete
                id="franchise"
                options={franchisees}
                // options={franchisees.sort((a, b) => -b.school_type.localeCompare(a.school_type))}
                // groupBy={(option) => option.school_type}
                getOptionLabel={(option) => option.tenant_name}
                renderInput={(params) => <TextField {...params} label={t('franchise')}/>}
                value={franchisee}
                onChange={handleSelect}
                sx={{marginBottom: 2}}
                fullWidth
            />
			<Button disabled={disable_button} onClick={handleBulkTransfer} variant="contained" sx={{width: '100%'}}>
                {t("transfer")}
            </Button>
		</INastixDialog>
	)
}


const TransferChildrenToOtherFranchiseDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

    const { t } = useTranslation('transfer_children_to_other_franchise')

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<CloudSyncIcon/>}>
			<Typography> {t("transfer_children_to_franchise")} </Typography>
		</TableActionButon>
		<TransferChildrenToOtherFranchiseDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default TransferChildrenToOtherFranchiseDialogHandler