import HandoutsActions from "@actions/HandoutsActions/handoutsActions"
import INastixTabs from "@components/navigation/INastixTabs"
import MarkShirtsAsSentFranchiseHandler from "@components/tables/CRUD/HandoutsTables/NotSentTables/markShirtAsSentFranchise"
import MarkShirtsAsSentChildrenHandler from "@components/tables/CRUD/HandoutsTables/NotSentTables/markShirtsAsSentChildren"
import NotSentChildTable from "@components/tables/CRUD/HandoutsTables/NotSentTables/notSentChildTable"
import NotSentFranchiseeTable from "@components/tables/CRUD/HandoutsTables/NotSentTables/notSentFranchiseeTable"
import NotSentSchoolTable from "@components/tables/CRUD/HandoutsTables/NotSentTables/notSentSchoolTable"
import PrintShirtsFranchiseHandler from "@components/tables/CRUD/HandoutsTables/NotSentTables/printShirtsFranchise"
import PrintShirtsSchoolsHandler from "@components/tables/CRUD/HandoutsTables/NotSentTables/printShirtsSchools"
import SentChildTable from "@components/tables/CRUD/HandoutsTables/SentTables/sentChildTable"
import SentFranchiseeTable from "@components/tables/CRUD/HandoutsTables/SentTables/sentFranchiseeTable"
import SentSchoolTable from "@components/tables/CRUD/HandoutsTables/SentTables/sentSchoolTable"

import { HandoutType } from "@utils/interfaces/interfaces"
import { getLocalStorageCompany } from "@utils/localStorage/company"
import { ReactElement, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"

const handouts_tabs_map = {
    'shirts_not_sent': 1,
    'shirts_sent': 2
}

export interface Props {
    table_type: string
}

const HandoutsTabs = (props: Props) => {
    const {table_type} = props

    // const { t } = useTranslation('handouts')
    const { t } = useTranslation('navigation')

    // const company = getLocalStorageCompany()

    const handouts_actions = new HandoutsActions()

    const [tabs, setTabs] = useState<ReactElement[]>([])

    const location = useLocation()
    const tab_key = new URLSearchParams(location.search).get('tab')

    useEffect(() => {
        if (table_type === 'children') {
            setTabs([
                <NotSentChildTable action={handouts_actions} dialogs={[]} handout={HandoutType.TSHIRT} filter={{'tshirt': false}} />,
                <SentChildTable action={handouts_actions} dialogs={[]} handout={HandoutType.TSHIRT} filter={{'tshirt': true}} />
            ])
        } else if (table_type === 'franchise') {
            setTabs([
                <NotSentFranchiseeTable action={handouts_actions} dialogs={[PrintShirtsFranchiseHandler, MarkShirtsAsSentFranchiseHandler]} handout={HandoutType.TSHIRT} filter={{'tshirt': false, 'franchise': true}} />,
                <SentFranchiseeTable action={handouts_actions} dialogs={[]} handout={HandoutType.TSHIRT} filter={{'tshirt': true, 'franchise': true}} />
            ])
        } else if (table_type === 'schools') {
            setTabs([
                <NotSentSchoolTable action={handouts_actions} dialogs={[PrintShirtsSchoolsHandler]} handout={HandoutType.TSHIRT} filter={{'tshirt': false, 'is_schools': true}} />,
                <SentSchoolTable action={handouts_actions} dialogs={[]} handout={HandoutType.TSHIRT} filter={{'tshirt': true, 'is_schools': true}} />
            ])
        }
    }, [table_type])


    const nav_items = [t('shirts_not_sent'), t('shirts_sent')]

    

    return (
        <INastixTabs
            nav_items={nav_items}
            tab_items={tabs}
            tabs_map={handouts_tabs_map}
            tabs_key={tab_key}
        />
    )
}

export default HandoutsTabs