import Pages from "@pages/Page"
import { PageHeadingTypography } from "@styles/Typography"
import { UserPermissions } from "@utils/enums/permissions"
import { useTranslation } from "react-i18next"
import HandoutsTabs from "./HandoutsTabs"
import { getLocalStorageCompany } from "@utils/localStorage/company"
import { useContext, useEffect, useState } from "react"
import { ChangeCompanyRerenderContext } from "@contexts/TriggerRerender/companyRerender"
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"


const HandoutsPage = () => {
    // const { t } = useTranslation('handouts')
    const { t } = useTranslation('navigation')

    const company = getLocalStorageCompany()

    const acts_like_a_franchise = company.acts_like_a_franchise

    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

    const [report_type, setReportType] = useState(acts_like_a_franchise ? 'schools' : 'franchise')

    useEffect(() => {
        setReportType(acts_like_a_franchise ? 'schools' : 'franchise')
    }, [change_company_rerender, acts_like_a_franchise])

    const handleChange = (event: SelectChangeEvent) => {
        setReportType(event.target.value as string)
    }

    return (
        <Pages page_title="Handouts" permission={UserPermissions.EDIT_CHILDREN}>
            <PageHeadingTypography> {t('handouts')} </PageHeadingTypography>
            <FormControl fullWidth>
                <InputLabel id="report-for-label">{t('report_type')}</InputLabel>
                <Select
                labelId="report-for-select-label"
                id="report-for-select"
                value={report_type}
                label="Report Type"
                onChange={handleChange}
                sx={{
                    marginBottom: '1rem', width: '200px'
                }}
                >
                    {!acts_like_a_franchise && <MenuItem value={'franchise'}>{t('franchisees')}</MenuItem>}
                    <MenuItem value={'schools'}>{t('schools')}</MenuItem>
                    <MenuItem value={'children'}>{t('children')}</MenuItem>
                </Select>
            </FormControl>
            <HandoutsTabs table_type={report_type}/>
        </Pages>
    )
}

export default HandoutsPage