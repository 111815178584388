import { CRUDAction } from "@actions/Actions/actions";
import Table, { DialogComponent } from "@components/tables/Table/Table";
import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { HandoutType } from "@utils/interfaces/interfaces";
import { getLocalStorageCompany } from "@utils/localStorage/company";
import { useTranslation } from "react-i18next";

export interface TableProps {
    action: CRUDAction
    dialogs: DialogComponent[]
    handout: HandoutType
    filter: Object
}

export const handoutsTableValueGetter = (params: any) => {
	
}

export const renderConnectedFranchise = (params: any) => {
	if (params.row.tenant_name) {
		return params.row.tenant_name
	}
	return ""
}

const SentSchoolTable = (props: TableProps) => {
    const {action, dialogs, handout, filter} = props

    // const { t } = useTranslation('handouts')
    const { t } = useTranslation('navigation')

    const company = getLocalStorageCompany()

    const is_franchisee = company.acts_like_a_franchise

    const columns = [
        { field: 'id', headerName: 'id' },
        { field: 'tenant_name', headerName: "Franchise", cellClassName: '', minWidth: 270, renderCell: renderConnectedFranchise},
        { field: 'school_name', headerName: t('school'), minWidth: 150, flex: 0.2 },
        { field: 'school_type', headerName: t('school_type'), minWidth: 200, flex: 0.2 },
        { field: 'extracurricular_activitiy_type', headerName: t('extracurricular_activitiy_type'), minWidth: 200, flex: 0.2 },
        { field: 'num_children', headerName: t('num_children'), minWidth: 150, flex: 0.2 },
    ]

    const column_visibility_model: GridColumnVisibilityModel = {}
    
    if (is_franchisee)
        column_visibility_model['tenant_name'] = false

    return (
        <Table
            dialogs={dialogs}
            action={action}
            filter={filter}
            columns={columns}
            column_visibility_model={column_visibility_model}
            page_size={100}
            amount_of_rows_selection={[100]}
            sort_by_asc="school_name"
        />
    )
}

export default SentSchoolTable