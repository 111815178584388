import Table, { DialogComponent } from "@components/tables/Table/Table";
import { useTranslation } from "react-i18next";
import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { getLocalStorageCompany } from "@utils/localStorage/company";
import { HandoutType } from "@utils/interfaces/interfaces";
import { Tooltip } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import DoNotDisturb from '@mui/icons-material/DoNotDisturb'
import AccessTime from '@mui/icons-material/AccessTime'
import { CRUDAction } from "@actions/Actions/actions";
import { INASTIX_SHIRT_MAP } from "../../ChildTable";


export interface TableProps {
    action: CRUDAction
    dialogs: DialogComponent[]
    handout: HandoutType
    filter: Object
}

export const handoutsTableValueGetter = (params: any) => {
	if (params.field === 'child_start_date') {
		const datePart = new Date(params.value).toISOString().split('T')[0]
		return datePart
	}

    else if (params.field === 'shirt_size')
        return INASTIX_SHIRT_MAP[params.value] || ''
}

export const renderConnectedFranchise = (params: any) => {
	if (params.row.franchise_name) {
		return params.row.franchise_name
	}
	return ""
}

const NotSentChildTable = (props: TableProps) => {
    const {action, dialogs, handout, filter} = props
    // const { t } = useTranslation('handouts')
    const { t } = useTranslation('navigation')

    const company = getLocalStorageCompany()

    const is_franchisee = company.acts_like_a_franchise

    const columns = [
        { field: 'id', headerName: 'id' },
        { field: 'child_name', headerName: t('name'), minWidth: 150, flex: 0.2 },
        { field: 'franchise_name', headerName: "Franchise", cellClassName: '', minWidth: 270, renderCell: renderConnectedFranchise},
        { field: 'school_name', headerName: t('school'), minWidth: 150, flex: 0.2 },
        { field: 'child_start_date', headerName: t('start_date'), minWidth: 120, flex: 0.2, valueGetter: handoutsTableValueGetter},
        { field: 'shirt_size', headerName: t('shirt_size'), valueGetter: handoutsTableValueGetter },
        // { field: 'authorized', sortable: false, headerName: t('authorised'), cellClassName: 'center-aligned-cell',
		// 	renderCell: (params: any) => {
		// 		if (params.value === 1) 
		// 			return <Tooltip title={"Authorised"}><CheckCircleIcon sx={{ color: '#51b77f' }} /></Tooltip>
		// 		else if (params.value === 2) 
		// 			return <Tooltip title={"Pending payment"}><PendingActionsIcon sx={{ color: 'orange' }} /></Tooltip>
		// 		else if (params.value === 3) 
		// 			return <Tooltip title={"Unauthorised"}><CancelIcon sx={{ color: '#ed586e' }} /></Tooltip>
		// 		else if (params.value === 4) 
		// 			return <Tooltip title={"Pending payment"}><PendingActionsIcon sx={{ color: 'orange' }} /></Tooltip>
		// 		else if (params.value === 5) 
		// 			return <Tooltip title={"On hold"}><AccessTime sx={{ color: 'blue' }} /></Tooltip>
		// 		else if (params.value === 6) 
		// 			return <Tooltip title={"Left"}><DoNotDisturb sx={{ color: '#ed586e' }} /></Tooltip>
		// 	},
		// },
    ]

    const column_visibility_model: GridColumnVisibilityModel = {}

    
    if (is_franchisee)
        column_visibility_model['franchise_name'] = false

    return (
        <Table
            dialogs={dialogs}
            action={action}
            filter={filter}
            columns={columns}
            column_visibility_model={column_visibility_model}
            page_size={100}
            amount_of_rows_selection={[100]}
            sort_by_asc="child_name"
        />
    )
}

export default NotSentChildTable